






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import SignupLoginLayout from '@/components/shared/layouts/SignupLoginLayout.vue';
import { EMAIL_REGEX, USERNAME_REGEX, PASSWORD_REGEX } from '@/scripts/shared';
import { required, sameAs } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import { TranslateResult } from 'vue-i18n';
import * as storeTypes from '@/store/types';
import { mutations } from '@/store/flow/mutations';

const namespace: string = 'user';

@Component({
  components: {
    SignupLoginLayout,
  },
})
export default class SignupRequest extends Vue {
  @Action(storeTypes.SIGNUP_REQUEST, { namespace })
  signupRequest: any;

  @Action(storeTypes.RESET_SIGNUP_REQUEST, { namespace })
  resetSignupRequest: any;

  @Getter(storeTypes.GET_SIGNUP_REQUEST_RESULT, { namespace })
  isSignupSent: any;

  private email: string = '';

  get emailErrors() {
    const errors: TranslateResult[] = [];
    if (!this.$v.email.$dirty) return errors;

    if (!this.$v.email.matches) errors.push(this.$t('email_invalid'));
    if (!this.$v.email.required) errors.push(this.$t('required_field'));
    return errors;
  }

  beforeDestroy() {
    this.resetSignupRequest();
  }

  @Validations()
  validations = {
    email: {
      required,
      matches(value: string) {
        return EMAIL_REGEX.test(value);
      },
    },
  };

  handleSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.signupRequest(this.email.toLowerCase());
  }
}
